.ibm__app-bar {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
}

.ibm__app-bar-top {
    display: flex;
    height: 92px;
    align-items: center;
    justify-content: space-between;
    background-color: #094183;
}

.ibm__max-width {
    display: flex;
    width: 90%;
    max-width: 1000px;
    margin: auto;
    justify-content: space-between;
}

.ibm__app-bar-header {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    font-size: 1.25rem;
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.05;
    letter-spacing: normal;
    color: #ffffff;
}

.ibm__app-bar-section {
    display: flex;
    padding: 0 1rem;
}

.ibm__app-bar-footerlogo {
    display: flex;
    height: 40px;
}