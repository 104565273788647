/* From the UOM website */
.page-title::before {
  display: inline-block;
  position: relative;
  content: "";
  width: 1.25rem;
  border: 1px solid rgb(255, 255, 255);
  margin-bottom: 0.75rem;
}

.page-title:visited {
  color: rgb(255, 255, 255);
}

.page-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  position: relative;
  margin: 0.3125rem 1.25rem 0 0.625rem;
  text-decoration: none;
  line-height: 1;
  color: rgb(255, 255, 255);
}

@media (min-width: 900px) .page-title {
  font-size: 1.11rem;
}

@media (min-width: 481px) .page-title {
  line-height: 1.25;
}
@media (min-width: 481px) .page-title {
  margin-left: 1.25rem;
}
