@import url("https://fonts.googleapis.com/css?family=Noto+Serif|Source+Sans+Pro");
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
  min-height: 100vh;
  background-color: #f7f7f7;
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: border-box;
}
#root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  flex: 1 1 100%;
  min-height: 100vh;
  font-size: 16px;
}

h1 {
  font-family: "Noto Serif", sans-serif;
  font-size: "2.6rem";
  color: #094183;
}

.ibm__router-container-link {
  text-decoration: none;
  color: inherit;
}

.ibm__icon_container {
  padding-right: 5px;
  display: inline-block !important;
}

.ibm__icon_container i {
  display: block;
  font-size: 0.8rem !important;
}

.ibm__text_next_to_icon {
  font-size: 0.8rem !important;
  display: inline !important;
}

/* In progress spinner */

.changes-in-progess-overlay {
  position: fixed;
  /* Sit on top of the page content */
  display: block;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 1301;
  /* Specify a stack order in case you're using a different order for other elements */
  overflow-x: hidden;
  overflow-y: hidden;
}

.changes-in-progess-overlay .overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 5rem;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 5px solid #4d4d4d;
  /* Light grey */
  border-top: 5px solid #bfbfbf;
  /* Blue */
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

table {
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
}

td {
  border: none;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  display: table-cell;
  font-size: 0.875rem;
  font-family: Source Sans Pro;
  font-weight: 400;
  line-height: 1.43;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  vertical-align: inherit;
  padding: 3px 12px 3px 12px;
}

.td-blue {
  color: rgb(9, 65, 131);
  margin: 0;
}

.td-blue__bold {
  color: rgb(9, 65, 131);
  font-weight: bold;
  margin: 0;
}

.material-icons {
  color: black;
}

/* Brackets */
.bracket {
  position: relative;
  padding-left: 1rem;
}
/* .bracket::before {
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  display: inline-block;
  height: 50%;
  content: "";
  width: 0.5rem;
  position: absolute;
  left: 0;
  bottom: 50%;
} */
.bracket::before {
  border-left: 1px solid black;
  display: inline-block;
  height: 100%;
  content: "";
  width: 0.5rem;
  position: absolute;
  left: 0;
  bottom: 5;
}
