.rulegroup-class {
  display: inline;
}

.rulegroup-class.higligth-rule-preview,
.higligth-rule-preview {
  display: block !important;
  background-color: #ebf8e1;
  padding: 2px 2px 2px 4px;
  /* border: 1px solid #69ba2c;
  border-radius: 2px;
  animation: pulse 1s 1;
  animation-delay: 1s; */
}

.higligth-rule-preview p,
.higligth-rule-preview div,
.higligth-rule-preview ul,
.higligth-rule-preview ol {
  font-weight: 500;
}

/* Based on https://codepen.io/olam/pen/zcqea */
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
